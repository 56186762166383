* {
  box-sizing: border-box;
}

@import url('https://rsms.me/inter/inter.css');
body { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  body { font-family: 'Inter var', sans-serif; }
}
#root {
  height: 100%;
  width: 100%;
}
body {
  font-size: 62.5%;
  margin: 0;
  /* padding: 1vw; */
  background-color: #eee;
  height: 100vh;
  width: 100vw;
  /* border: 2vw solid #eee; */
  overflow: hidden;
}
main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* height: calc(100vh - 4vw); */
  background-color: white;
  height: calc(100vh - 4vw);
  width: 96vw;
  /* padding: 2vw; */
  margin: 2vw;
  /* float: right; */
  overflow-y: auto;
  -ms-scroll-snap-type: mandatory;
      scroll-snap-type: mandatory;
  -ms-scroll-snap-points-y: repeat(calc(100vh - 4vw));
      scroll-snap-points-y: repeat(calc(100vh - 4vw));
  -ms-scroll-snap-type: y mandatory;
      scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.intro-area {
  position: relative;
  padding-bottom: calc(100vh - 4vw);
  width: 100%;
  margin-bottom: 4vw;
  scroll-snap-align: center;
  scroll-snap-stop: always;
}

.works-area, .exhibitions-area, .press-area, .links-area, .commissions-area {
  width: 100%;
}

.works-area {
  /* outline: 1px solid blue; */
}

.exhibitions-area {
  /* outline: 1px solid red; */
}

.links-area, .publications-area {
  position: relative;
  height: calc(100vh - 4vw);
  min-height: calc(100vh - 4vw);
  width: 100%;
  scroll-snap-align: center;
  scroll-snap-stop: always;
}

.links-area ul, .publications-area ul {
  font-size: 0.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  line-height: 1em;
  padding: 0 0 2vw 2vw;
  margin: 0;
  list-style-type: none;
  overflow-y: scroll;
  display: -webkit-flex;
  display: flex;
  align-content: flex-start;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
  width: 100%;
  height: calc(100% - 7vw);
  min-height: calc(100% - 7vw);
  max-height: calc(100% - 7vw);
  overflow-x: scroll;
}

.links-area ul li, .publications-area ul li {
  line-height: 1.5rem;
  width: 35vw;
  margin-right: 1rem;
  margin-bottom: 0.25rem;
}

.work,
.exhibition,
.commission {
  position: relative;
  height: calc(100vh - 4vw);
  width: 100%;
  /* float: left; */
  scroll-snap-align: center;
  scroll-snap-stop: always;
  margin: 0;
  /* outline: 1px solid yellow; */
}

.works-area, .press-area, .links-area, .publications-area {
  /* height: 100%; */
  float: left;
  padding-left: 2vw;
}

.exhibitions-area,
.commissions-area {
  padding: 0;
}

.work {
  scroll-snap-align: center;
  scroll-snap-stop: always;
  /* outline: 1px solid blue; */
  margin-bottom: 8vw;
}
.main-photo, .work-photo, .exhibition-images, .commissions-images {
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 50%;
  background-color: #fafafa;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 100%;
}
.main-photo {
  background-image: url('main.jpg');
  z-index: 6;
}
.work-photo {
  z-index: 6;
}

.exhibition-images,
.commission-images {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  height: calc(100% - calc(6vw + 1rem));
  top: initial;
  /* background-color: white; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: flex-start;

  -ms-scroll-snap-points-x: repeat(48vw);

      scroll-snap-points-x: repeat(48vw);
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.exhibition-photo,
.commission-photo {
  height: 100%;
  width: 96vw;
  /* width: calc(100vw - 4vw); */
  float: left;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 100%;
  margin: auto;
  flex-shrink: 0;
  scroll-snap-align: center;
  scroll-snap-stop: always;
}

.exhibition-nav,
.commission-nav {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2vw;
  width: 96vw;
  z-index: 2;
}

.exhibition-nav ul,
.commission-nav ul {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.exhibition-nav ul li,
.commission-nav ul li {
  width: 50%;
  float: left;
  color: black;
  font-size: 3rem;
  line-height: 2vw;
  cursor: pointer;
}
.exhibition-nav ul li:last-child,
.commission-nav ul li:last-child {
  text-align: right;
}

header {
  position: fixed;
  top: 2vw;
  left: 2vw;
  z-index: 3;
  background-color: white;
  padding: 2vw 0 2vw 2vw;
  width: calc(100% - 4vw);
}
h1 {
  cursor: pointer;
}
h1, .work-name, .exhibition-name, .commission-name {
  color: black;
  font: 2vw "Palatino Linotype", "Book Antiqua", Palatino, serif;
  font-weight: normal;
  text-transform: uppercase;
  line-height: 2vw;
  height: 2vw;
  margin: 0;
}

.nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  float: left;
}

.nav li {
  display: inline-block;
  /* margin-right: 1vw; */
}

.work-name, .exhibition-name, .press-name, .commission-name {
  font-size: 1.5vw;
  position: absolute;
  bottom: 0;
  left: 0;
  line-height: 1em;
  height: 4.5vw;
  width: 50%;
  padding-bottom: 2vw;
  float: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.exhibition-name, .press-name, .commission-name {
  height: 3.5vw;
}
.exhibition-name,
.commission-name {
  width: 100%;
  text-align: center;
  z-index: 2;
  color: black;
}
.work-details {
  font-size: 1vw;
}
.cv {
  float: left;
}
.cv, .nav li a {
  width: auto;
  font-size: 1rem;
  line-height: 1rem;
  margin-right: 1vw;
}
.gallerists, .work-notes {
  position: absolute;
  bottom: 2vw;
  left: 2vw;
  padding: 0;
  margin: 0;
  float: left;
  list-style-type: none;
  font-size: 1.2rem;
  line-height: 2rem;
}

.work-notes {
  max-width: 40vw;
  color: #333;
  margin: 0;
  padding: 0;
  left: 0;
  list-style-type: decimal;
  font: 4vw "Palatino Linotype", "Book Antiqua", Palatino, serif;
}

.work-notes li {
  font-size: 1vw;
  margin: 0;
  padding: 0;
}

.gallerists li, .work-notes li {
  margin-bottom: 2rem;
}
.gallerists li:last-child, .work-notes li:last-child {
  margin-bottom: 0;
}
a {
  color: #333;
  text-decoration: none;
}
a:after {
  content: '\2192';
  transition: all 100ms linear;
}
a#exhibitions-link:after,
a#commissions-link:after,
a#works-link:after,
a#press-link:after,
a#publications-link:after {
  content: '\2193';
}

@media not all and (pointer: coarse) {
  a:hover {
    color: #666;
  }
  a:hover:after {
    content: '\2192';
    color: black;
    padding-left: 1vw;
  }

  a#exhibitions-link:hover:after,
  a#commissions-link:hover:after,
  a#works-link:hover:after,
  a#press-link:hover:after,
  a#publications-link:hover:after {
    content: '\2193';
    padding-left: 0;
    transform: translateY(1vw);
  }
}
.gallerists .location {
  display: block;
  width: 100%;
  float: left;
  font-size: 1rem;
  line-height: 1rem;
}

@media all and (max-width: 800px) {
  body {
    background-color: white;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    padding: 2vw;
    /* font-size: 4vw; */
  }
  header {
    /* margin-top: calc(50vh - (8vw + 40px)); */
    background-color: transparent;
  }
  /* .cv a, .nav li a {
    font-size: 0.75rem;
    line-height: 1.5rem;
    padding: 0.25rem 0.25rem 0.25rem 0;
    margin-right: 0.75rem;
  } */
  .gallerists {
    bottom: calc(2vw + 65px);
    z-index: 1;
  }
  .main-photo {
    background-color: #ccc;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    background-position: 50% 50%;
  }
  h1, a {
    color: white;
    /* color: black; */
  }
  h1 {
    font-size: 6vw;
    height: 6vw;
    line-height: 6vw;
  }
  .exhibitions-area, .works-area, .links-area, .publications-area, .commissions-area {
    background-color: #ccc;
    padding: 0;
  }
  .exhibition-images,
  .commission-images {
    height: 100%;
  }
  .exhibition-photo,
  .commission-photo {
    background-color: #ccc;
    background-position: 50% 50%;
  }

  .work-photo {
    background-color: #ccc;
    width: 100%;
    z-index: 0;
    background-position: 50% 50%;
  }
  .exhibition-name, .work-name, .commission-name {
    color: white;
    font-size: 0.85rem;
    bottom: calc(4vw + 65px);
    z-index: 1;
    height: 4vw;
  }
  .work-name, .exhibition-name, .commission-name {
    width: 100%;
    left: 0;
    bottom: 65px;
    height: 10vw;
    background-color: rgba(127, 127, 127, 0.4);
    padding: 2vw;
    text-align: left;
  }
  .exhibition-name, .commission-name {
    height: calc(4vw + 0.75em);
  }
  .work-details {
    font-size: 0.85rem;
    color: white;
  }
  .exhibition-nav, .commission-nav {
    bottom: calc(14vw + 65px);
  }
  .exhibition-nav ul li,
  .commission-nav ul li {
    color: white;
  }
  .publications-area ul, .links-area ul {
    bottom: 65px;
  }
  .publications-area ul, .links-area ul {
    height: calc(100vh - calc(22vw + 65px));
    min-height: calc(100vh - calc(22vw + 65px));
    /* bottom: 65px; */
  }
  .publications-area ul li, .links-area ul li {
    line-height: 1rem;
    padding-bottom: 2vw;
  }
  .publications-area ul li.publication .publication-name,
  .links-area ul li.link .link-name {
    font-size: 0.6rem;
    line-height: 1em;
  }
}
@media not all and (pointer: coarse) {
    a:hover {
      color: #ddd;
    }
    a:hover:after {
      color: #ddd;
      padding-left: 1vw;
    }
    a#exhibitions-link:hover:after,
    a#commissions-link:hover:after,
    a#works-link:hover:after,
    a#press-link:hover:after,
    a#publications-link:hover:after {
      display: inline-block;
      content: '\2193';
      padding-left: 0;
      transform: translateY(1vw);
    }
    header {
      /* margin-top: calc(50vh - 8vw); */
    }
    .gallerists {
      bottom: 2vw;
    }
  }